export class ProductUpdateRequest {
    categoryId: number | null = null;
    brandId: number | null = null;
    imageUrls: string = "";
    name: string = '';
    description: string = '';
    formula: string | any = null;
    sex: string | any = null;
    weight: number = 0;
    guaranteePeriod: string | any= null;
    size: number | any = null;
    smell: string | any = null;
    status: string | any = null;
    warehouseId: number | any = null;

    constructor(data?: Partial<ProductUpdateRequest>) {
        if (data) {
            Object.assign(this, data)
        }
    }
}