import React from 'react';
// import { useAuth } from './contexts/AuthenticateContext';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './components/RouteWithLayout';
import { MainLayout, SigninLayout } from './layouts';
import SignIn from './pages/SignIn';
import SellerList from './pages/seller/list/SellerList';
import Products from './pages/product/list/Products';
import ProductDetail from './pages/product/detail/ProductDetail';
import ProductCreate from './pages/product/create/ProductCreate';
import Categories from './pages/category/list/Categories';
import Brands from './pages/brands/list/Brands';
import Configuration from './pages/configuration/Configuration';

const Routes: React.FC = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/admin/products"
            />
            <RouteWithLayout
                component={SignIn}
                exact
                layout={SigninLayout}
                path="/admin/sign-in"
            />
            <RouteWithLayout
                component={SellerList}
                layout={MainLayout}
                path="/admin/sellers"
            />
            <RouteWithLayout
                component={ProductCreate}
                layout={MainLayout}
                path="/admin/products/create"
            />
            <RouteWithLayout
                component={ProductDetail}
                layout={MainLayout}
                path="/admin/products/:id"
            />
            <RouteWithLayout
                component={Products}
                layout={MainLayout}
                path="/admin/products"
            />
            <RouteWithLayout
                component={Categories}
                layout={MainLayout}
                path="/admin/categories"
            />
            <RouteWithLayout
                component={Brands}
                layout={MainLayout}
                path="/admin/brands"
            />
            <RouteWithLayout
                component={Configuration}
                layout={MainLayout}
                path="/admin/configuration"
            />
            
        </Switch>
    )
}

export default Routes;