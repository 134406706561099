import { Grid, Modal, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrandDomain } from '../../../services/brand/types/BrandDomain';
import { CategoryDomain } from '../../../services/category/type/CategoryDomain';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { optionSnackbarError, optionSnackbarSuccess } from '../../../utilities/Option';

interface IProps {
    isOpen: boolean,
    onSubmit: (brand: any) => void,
    onClose: () => void,
    brand: CategoryDomain
}

const BrandCreateModal: React.FC<IProps> = (props: IProps) => {
    const [ brand, setBrand ] = useState<BrandDomain>(new BrandDomain());
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setBrand(props?.brand);
    }, [props?.brand?.id]);

    const onSubmit = async () => {
        if (!brand.name) {
            enqueueSnackbar("Tên thương hiệu không được bỏ trống!", optionSnackbarError);
            return;
        }
        try {
            const categoryCreateRequest = {
                name: brand.name,
                id: brand?.id || null,
                status: brand?.status
            };
            categoryCreateRequest.id = brand?.id;
            props.onSubmit(categoryCreateRequest);
            enqueueSnackbar(`${props?.brand?.id ? 'Cập nhật' : 'Thêm mới'} thương hiệu thành công`, optionSnackbarSuccess);
            props.onClose();
        } catch (error) {

        }
    }

    return (
        <Modal
            open={props?.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ alignItems:'center',justifyContent:'center', display: 'flex' }}
        >
            <Paper style={{ clear: "both", padding: '20px', width: '70%' }}>
                <h2 id="server-modal-title">{props?.brand?.id ? 'Chỉnh sửa' : 'Thêm mới'} thương hiệu</h2>
                <Grid container spacing={2}>
                    <Grid item container alignItems='center'>
                        <Grid className='product-detail__label' xs={4}>Tên thương hiệu</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={brand.name}
                                onChange={(e: any) => setBrand({ ...brand, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid justify='flex-end' container className='mt-15'>
                    <button onClick={props.onClose} className="btn">
                        Hủy bỏ
                    </button>
                    <button type="submit" onClick={onSubmit} className="btn sapo-btn-default ml-15" autoFocus>
                        Xác nhận
                    </button>
                </Grid>
                </Paper>
        </Modal>
    )
}
export default BrandCreateModal;