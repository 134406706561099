import { makeStyles, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader/PageHeader';
import BrandService from '../../../services/brand/BrandService';
import { BrandDomain } from '../../../services/brand/types/BrandDomain';
import { CategoryDomain } from '../../../services/category/type/CategoryDomain';
import BrandCreateModal from '../create/BrandCreate';

interface IProps extends RouteComponentProps {

}

const useStyles = makeStyles({
    caption: {
        flexGrow: 'unset',
        marginRight: '10px',
    },
    toolbar: {
        justifyContent: 'flex-end',
    }
});

const Brands: React.FC<IProps> = (props: IProps) => {
    const title = "Danh sách thương hiệu";
    const [ brands, setBrands ] = useState<Array<BrandDomain>>([]);
    const [ isOpenModal, setOpenModal ] = useState<boolean>(false);
    const [ brandSelected, setCategorySelected ] = useState<CategoryDomain>(new CategoryDomain());

    useEffect(() => {
        filterBrands();
    }, []);

    const filterBrands = async () => {
        try {
            const response = await BrandService.filterBrands();
            setBrands(response);
            // setPagination(response.pagination);
        } catch (error) {
        }
    }

    const onCloseModal = () => {
        setOpenModal(false);
    }

    const onOpenModal = () => {
        setOpenModal(true);
    }

    const openDetailCategory = (category: CategoryDomain) => {
        setCategorySelected(category);
        setOpenModal(true);
    }

    const handleChangeBrand = async (category: any) => {
        try {
            await BrandService.upsert(category);
            filterBrands();
            onCloseModal();
        } catch (error) {

        }
    }

    return (
        <div className="seller-list">
            <PageHeader
                title={title}
                actionGroup={<div><button className='btn sapo-btn-default' onClick={onOpenModal}>Thêm mới</button></div>}
            ></PageHeader>
            <Paper style={{ clear: "both" }}>
                <MaterialTable
                    columns={[
                        {
                            title: 'Tên',
                            field: 'name',
                            render: (row: any) => {
                                return (
                                    <div className='link' onClick={() => openDetailCategory(row)}>{row?.name}</div>
                                )
                            }
                        },
                        // {
                        //     title: 'Ảnh',
                        //     render: (rowData) => {
                        //         return (
                        //             <span>
                        //                 <img style={{ height: '100px' }} src={rowData?.imageUrl}  onClick={() => window.open(rowData?.imageUrl, "_blank")}/>
                        //             </span>
                        //         );
                        //     }
                        // },
                        {
                            title: 'Ngày tạo',
                            render: (row: any) => (
                                <div>{row.createdAt ? moment(row.createdAt).local().format("DD/MM/YYYY") : ""}</div>
                            )
                        },
                        {
                            title: 'Trạng thái',
                            render: (row: any) => (
                                <span>{row?.status}</span>
                            )
                        },
                        // {
                        //     title: 'Action',
                        //     render: (row: any) => (
                        //         <MoreVertIcon
                        //             style={{ cursor: 'pointer' }}
                        //         />
                        //     )
                        // }
                    ]}
                    data={brands}
                    options={{
                        selection: false,
                        search: false,
                        paging: false,
                        sorting: false,
                        headerStyle: { position: 'sticky', top: 0, backgroundColor: '#E7E9EB', fontSize: "13px" },
                        maxBodyHeight: 'calc(100vh - 250px)',
                        toolbar: false,
                        rowStyle: { fontSize: '13px' }
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0} className="table-material" />
                    }}
                />
            </Paper>

            <BrandCreateModal
                isOpen={isOpenModal}
                onClose={onCloseModal}
                onSubmit={handleChangeBrand}
                brand={brandSelected}
            />
        </div>
    )
}
export default Brands;