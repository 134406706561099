import { Box, Grid } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import fileService from '../../services/file/FileService';
import ImageBox from '../ImageBox';
import Spinner from '../Spin/Spinner';


export interface Props {
    onDropAccepted?(files: File[]): void,
    onDropRejected?(files: File[]): void,
    onDelete?: (index: number) => void,
    addImageURL(url?: string): void,
    images: Array<string>,
    single?: boolean
}

function ImagesUpload(props: Props) {
    const { onDropAccepted, onDropRejected, images, onDelete, addImageURL } = props;
    const [ loading, setLoading ] = useState(false);

    const onChangeImage = async (e: any) => {
        try {
            setLoading(true);
            const file = e.target.files[0];
            const response = await fileService.upload(file);
            props.addImageURL(response);
        } catch(error) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Grid container>
                {
                    props?.images?.length > 0 && props?.images?.map((image: string, index: number) => (
                        <Grid item xs={3} style={{ position: 'relative' }}>
                            <ImageBox
                                imageUrl={image}
                                width="100%"
                                height="100%"
                            />
                            <DeleteIcon
                                style={{ position: 'absolute', cursor: 'pointer', color: 'red', top: 0, right: 0 }}
                                onClick={() => { if(onDelete) { onDelete(index) }}}
                            />
                        </Grid>
                    ))
                }

                {
                    (!props.single || !props?.images?.length) && (
                        <Grid item xs={3} justify="center" alignItems='center'>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                <div style={{ position: 'relative'}}>
                                    {/* <DeleteIcon style={{ position: 'absolute', cursor: 'pointer', color: 'red', top: 0, right: 0 }} /> */}
                                    <BackupIcon />
                                    <input type='file' style={{ position: 'absolute', left: 0, opacity: 0 }} onChange={onChangeImage} />
                                </div>
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
            {loading && (<Spinner />)}
        </>
    );
}

export default ImagesUpload;
