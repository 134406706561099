import apiClient from "../../utilities/ApiClient";
import { VariantUpsertRequest } from "./type/VariantUpsertRequest";

const variantService = {
    async upsert(request: VariantUpsertRequest): Promise<any> {
        try {
            let response = await apiClient.post<any>("api/variants/upsert", request)
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default variantService;
