export class VariantUpsertModel {
    id: number | any= null;
    sku: string = '';
    volume: number | any= null;
    price: number | any= null;
    totalPrice: number | any = null;

    constructor(data?: Partial<VariantUpsertModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
