import { PaginationRequest } from "../../pagination/PaginationRequest";

export class ProductFilterRequest extends PaginationRequest {
    brandIds: string | null = null;
    categoryIds: string | null = null;
    keyword: string = "";
    priceFrom: number | null = null;
    priceTo: number | null = null;

    constructor(data?: Partial<ProductFilterRequest>) {
        super();
        if (data) {
            Object.assign(this, data)
        }
    }
}