export class CategoryDomain {
    id: number | any = null;
    name: string = '';
    imageUrl: string | any = null;
    status: string = 'ACTIVATE';

    constructor(data?: Partial<CategoryDomain>) {
        if (data) {
            Object.assign(this, data)
        }
    }
}