import { CircularProgress, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import ImagesUpload from '../../components/ImagesUpload/ImagesUpload';
import PageHeader from '../../components/PageHeader/PageHeader';
import Spinner from '../../components/Spin/Spinner';
import ConfigurationService from '../../services/configuration/configurationService';
import { ConfigurationDomain } from '../../services/configuration/type/ConfigurationDomain';
import { optionSnackbarError, optionSnackbarSuccess } from '../../utilities/Option';

interface IProps {
    
}

const Configuration: React.FC<IProps> = (props: IProps) => {
    const [ configuration, setConfiguration ] = useState<ConfigurationDomain>(new ConfigurationDomain());
    const [ isLoading, setLoading ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        fetchDetail()
    }, []);

    const handleChangeBanner = (url: string) => {
        setConfiguration({ ...configuration, bannerUrl: url})
    }

    const fetchDetail = async () => {
        try {
            const response = await ConfigurationService.get();
            setConfiguration(response);
        } catch (error) {

        }
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await ConfigurationService.save(configuration);
            enqueueSnackbar("Chỉnh sửa cấu hình thành công!", optionSnackbarSuccess);
        } catch (error) {
            enqueueSnackbar("Có lỗi xảy ra!", optionSnackbarError);
        } finally {
            setLoading(false);
        }
    }

    const removeImage = () => {
        setConfiguration({ ...configuration, bannerUrl: "" })
    }

    return (
        <div className="seller-list">
            <PageHeader
                title={"Cấu hình website"}
                actionGroup={<div><button className='btn sapo-btn-default' onClick={isLoading ? () => {} : () => onSubmit()}>{isLoading ? <CircularProgress /> : 'Lưu'}</button></div>}
            ></PageHeader>
            <Grid container spacing={2}>
                <Grid item container alignItems='center'>
                    <Grid className='product-detail__label' xs={4}>Header</Grid>
                    <Grid xs={8}>
                        <input
                            className='sapo-textbox'
                            value={configuration.headerContent}
                            onChange={(e: any) => setConfiguration({ ...configuration, headerContent: e.target.value })}
                        />
                    </Grid>
                </Grid>

                <Grid item container alignItems='center'>
                    <Grid className='product-detail__label' xs={4}>Ảnh banner</Grid>
                    <Grid xs={8}>
                        <ImagesUpload
                            images={!configuration?.bannerUrl ? [] : [configuration?.bannerUrl]}
                            addImageURL={(url: string) => handleChangeBanner(url)}
                            onDelete={removeImage}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {
                isLoading && (<Spinner />)
            }
        </div>
    )
}
export default Configuration;