import { CircularProgress } from "@material-ui/core";
import React from "react";

function Spinner() {
    return (
        <div className="overlay">
            <CircularProgress color="inherit" />
        </div>
    );
}

export default Spinner;
