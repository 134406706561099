import { Grid, Modal, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ImagesUpload from '../../../components/ImagesUpload/ImagesUpload';
import CategoryService from '../../../services/category/CategoryService';
import { CategoryCreateRequest } from '../../../services/category/type/CategoryCreateRequest';
import { CategoryDomain } from '../../../services/category/type/CategoryDomain';

interface IProps {
    isOpen: boolean,
    onSubmit: (category: any) => void,
    onClose: () => void,
    category: CategoryDomain
}

const CategoryCreateModal: React.FC<IProps> = (props: IProps) => {
    const [ category, setCategory ] = useState<CategoryDomain>(new CategoryDomain());

    useEffect(() => {
        setCategory(props?.category);
    }, [props?.category?.id]);

    const handleChangeImage = (url: string) => {
        const newCategory =  { ...category };
        newCategory.imageUrl = url;
        setCategory(newCategory);
    }

    const onSubmit = async () => {
        try {
            const categoryCreateRequest = {
                imageUrl: category.imageUrl,
                name: category.name,
                id: category?.id || null,
                status: category?.status
            };
            categoryCreateRequest.id = category?.id;
            props.onSubmit(categoryCreateRequest);
        } catch (error) {

        }
    }

    const removeImage = (index: number) => {
        const newCategory = { ...category };
        newCategory.imageUrl = null;
        setCategory(newCategory);
    }

    return (
        <Modal
            open={props?.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ alignItems:'center',justifyContent:'center', display: 'flex' }}
        >
            <Paper style={{ clear: "both", padding: '20px', width: '70%' }}>
                <h2 id="server-modal-title">{props?.category?.id ? 'Chỉnh sửa' : 'Thêm mới'} danh mục</h2>
                <Grid container spacing={2}>
                    <Grid item container alignItems='center'>
                        <Grid className='product-detail__label' xs={4}>Tên danh mục</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={category.name}
                                onChange={(e: any) => setCategory({ ...category, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item container alignItems='center'>
                        <Grid className='product-detail__label' xs={4}>Ảnh danh mục</Grid>
                        <Grid xs={8}>
                            {
                                <ImagesUpload
                                    images={!category?.imageUrl ? [] : [category?.imageUrl]}
                                    addImageURL={(url: string) => handleChangeImage(url)}
                                    onDelete={removeImage}
                                    single={true}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid justify='flex-end' container className='mt-15'>
                    <button onClick={props.onClose} className="btn">
                        Hủy bỏ
                    </button>
                    <button type="submit" onClick={onSubmit} className="btn sapo-btn-default ml-15" autoFocus>
                        Xác nhận
                    </button>
                </Grid>
            </Paper>
        </Modal>
    )
}
export default CategoryCreateModal;