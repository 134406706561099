import { makeStyles, Paper, TablePagination } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { PaginationResponseModel } from '../../../services/pagination/PaginationResponse';
import MaterialTable from 'material-table';
import CustomTablePaginationActions from '../../../components/CustomTablePaginationActions';
import moment from 'moment';
import productService from '../../../services/product/productService';
import { ProductFilterRequest } from '../../../services/product/type/ProductsFilterRequest';

interface IProps extends RouteComponentProps {

}

const useStyles = makeStyles({
    caption: {
        flexGrow: 'unset',
        marginRight: '10px',
    },
    toolbar: {
        justifyContent: 'flex-end',
    }
});

const Products: React.FC<IProps> = (props: IProps) => {
    const title = "Danh sách Products";
    const classes = useStyles();
    const [ products, setProducts ] = useState<Array<any>>([]);
    const [ pagination, setPagination ] = useState<PaginationResponseModel>(new PaginationResponseModel());
    const [ filter, setFilter ] = useState<ProductFilterRequest>(new ProductFilterRequest());

    useEffect(() => {
        filterSellers(new ProductFilterRequest());
    }, []);

    useEffect(() => {
        filterSellers(filter);
    }, [filter.pageNo, filter.pageSize]);

    const filterSellers = async (request: ProductFilterRequest) => {
        try {
            const productFilterRequest = {
                ...request
            };
            const response = await productService.filterProducts(productFilterRequest);
            setProducts(response?.content);
            setPagination(response.pageInfo);
        } catch (error) {
        }
    }

    const handleChangePage = (e: any, page: number) => {
        setFilter({
            ...filter,
            pageNo: page + 1
        });
    }

    const handleChangeRowsPerPage = (e: any) => {
        setFilter({
            ...filter,
            pageSize: Number(e.target.value)
        });
    }

    const goToProductDetail = (id: any) => {
        props.history?.push(`/admin/products/${id}`)
    }

    return (
        <div className="seller-list">
            <PageHeader
                title={title}
                actionGroup={<div><button className='btn sapo-btn-default' onClick={() => props.history.push('/admin/products/create')}>Thêm mới</button></div>}
            ></PageHeader>
            <Paper style={{ clear: "both" }}>
                {/* <SellersFilter
                    setFilter={setFilter}
                    filter={filter}
                    filterSellers={filterSellers}
                /> */}
                <MaterialTable
                    columns={[
                        {
                            title: 'Tên',
                            field: 'name',
                            render: (row: any) => {
                                return (
                                    <div className='link' onClick={() => goToProductDetail(row?.id)}>{row?.name}</div>
                                )
                            }
                        },
                        {
                            title: 'Thương hiệu',
                            render: (rowData) => {
                                return (
                                    <span>
                                        {rowData?.brand?.name}
                                    </span>
                                );
                            }
                        },
                        {
                            title: 'Danh mục',
                            render: (rowData) => {
                                return (
                                    <span>
                                        {rowData?.category?.name}
                                    </span>
                                );
                            }
                        },
                        {
                            title: 'Ngày tạo',
                            render: (row: any) => (
                                <div>{row.createdAt ? moment(row.createdAt).local().format("DD/MM/YYYY") : ""}</div>
                            )
                        },
                        {
                            title: 'Trạng thái',
                            render: (row: any) => (
                                <span>Đang bán</span>
                            )
                        },
                    ]}
                    data={products}
                    options={{
                        selection: false,
                        search: false,
                        paging: false,
                        sorting: false,
                        headerStyle: { position: 'sticky', top: 0, backgroundColor: '#E7E9EB', fontSize: "13px" },
                        maxBodyHeight: 'calc(100vh - 150px)',
                        toolbar: false,
                        rowStyle: { fontSize: '13px' }
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0} className="table-material" />
                    }}
                />

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[50, 100, 200]}
                    count={pagination.total || 0}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.pageNo}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={props => (
                        <CustomTablePaginationActions {...props} />
                    )}
                    classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                />
            </Paper>
        </div>
    )
}

export default Products;