export default {
    gutterBottom: {
      marginBottom: 8
    },
    root:{
      fontSize:13
    }, 
    body2:{
      fontSize:13
    }
  };
  