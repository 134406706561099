import { makeStyles, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Spinner from '../../../components/Spin/Spinner';
import CategoryService from '../../../services/category/CategoryService';
import { CategoryDomain } from '../../../services/category/type/CategoryDomain';
import CategoryCreateModal from '../create/CategoryCreateModal';

interface IProps extends RouteComponentProps {

}

const Categories: React.FC<IProps> = (props: IProps) => {
    const title = "Danh sách danh mục";
    const [ categories, setCategories ] = useState<Array<CategoryDomain>>([]);
    const [ isOpenModal, setOpenModal ] = useState<boolean>(false);
    const [ categorySelected, setCategorySelected ] = useState<CategoryDomain>(new CategoryDomain());
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        filterCategories();
    }, []);

    const filterCategories = async () => {
        try {
            const response = await CategoryService.filterCategories();
            setCategories(response);
            // setPagination(response.pagination);
        } catch (error) {
        }
    }

    const onCloseModal = () => {
        setOpenModal(false);
        setCategorySelected(new CategoryDomain());
    }

    const onOpenModal = () => {
        setOpenModal(true);
    }

    const openDetailCategory = (category: CategoryDomain) => {
        setCategorySelected(category);
        setOpenModal(true);
    }

    const handleChangeCategory = async (category: any) => {
        try {
            setLoading(true);
            if (category?.id) {
                await CategoryService.update(category);
            } else {
                await CategoryService.insert(category);
            }
            filterCategories();
            onCloseModal();
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }
    

    return (
        <div className="seller-list">
            <PageHeader
                title={title}
                actionGroup={<div><button className='btn sapo-btn-default' onClick={onOpenModal}>Thêm mới</button></div>}
            ></PageHeader>
            <Paper style={{ clear: "both" }}>
                <MaterialTable
                    columns={[
                        {
                            title: 'Tên',
                            field: 'name',
                            render: (row: any) => {
                                return (
                                    <div className='link' onClick={() => openDetailCategory(row)}>{row?.name}</div>
                                )
                            }
                        },
                        {
                            title: 'Ảnh',
                            render: (rowData) => {
                                return (
                                    <span>
                                        <img style={{ height: '100px' }} src={rowData?.imageUrl}  onClick={() => window.open(rowData?.imageUrl, "_blank")}/>
                                    </span>
                                );
                            }
                        },
                        {
                            title: 'Ngày tạo',
                            render: (row: any) => (
                                <div>{row.createdAt ? moment(row.createdAt).local().format("DD/MM/YYYY") : ""}</div>
                            )
                        },
                        {
                            title: 'Trạng thái',
                            render: (row: any) => (
                                <span>{row?.status}</span>
                            )
                        },
                        // {
                        //     title: 'Action',
                        //     render: (row: any) => (
                        //         <MoreVertIcon
                        //             style={{ cursor: 'pointer' }}
                        //         />
                        //     )
                        // }
                    ]}
                    data={categories}
                    options={{
                        selection: false,
                        search: false,
                        paging: false,
                        sorting: false,
                        headerStyle: { position: 'sticky', top: 0, backgroundColor: '#E7E9EB', fontSize: "13px" },
                        maxBodyHeight: 'calc(100vh - 250px)',
                        toolbar: false,
                        rowStyle: { fontSize: '13px' }
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0} className="table-material" />
                    }}
                />
            </Paper>

            <CategoryCreateModal
                isOpen={isOpenModal}
                onClose={onCloseModal}
                onSubmit={handleChangeCategory}
                category={categorySelected}
            />
            {
                loading && (<Spinner />)
            }
        </div>
    )
}
export default Categories;