import apiClient from "../../utilities/ApiClient";

const fileService = {
    async upload(file: any): Promise<any> {
        try {
            const request = new FormData();
            request.append("file", file);
            debugger
            let response = await apiClient.post<any>("api/file/upload", request);
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default fileService;
