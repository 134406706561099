export default {
    spacer: {
        flex: "unset",
    },
    caption: {
        flexGrow : 1
    },
    root: {
        fontSize:13
    }
  };