import apiClient from "../../utilities/ApiClient";
import { CategoryCreateRequest } from "./type/CategoryCreateRequest";
import { CategoryDomain } from "./type/CategoryDomain";

const CategoryService = {
    async filterCategories(): Promise<Array<CategoryDomain>> {
        try {
            let response = await apiClient.get<Array<CategoryDomain>>("api/categories");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async insert(request: CategoryCreateRequest): Promise<any> {
        try {
            const response = await apiClient.post<CategoryDomain>("api/categories", request);

            return response
        } catch (error) {
            
        }
    },
    async update(request: any): Promise<any> {
        try {
            const response = await apiClient.put<CategoryDomain>(`api/categories/${request?.id}`, request);

            return response
        } catch (error) {
            
        }
    },
}

export default CategoryService;
