type MenuItem = {
    id: number,
    name: string,
    path: string,
    icon: any,
    subMenus: Array<SubMenu> | null
};
type SubMenu = {
    path: string,
    name: string
}
export const menuItems: Array<MenuItem> = [
    {
        id: 1,
        name: 'Sản phẩm',
        path: '/admin/products',
        icon: "/images/icons/menu-products-icon.svg",
        subMenus: [
            { path: "/admin/products", name: "Danh sách sản phẩm" }
        ]
    },
    {
        id: 2,
        name: 'Danh mục sản phẩm',
        path: '/admin/categories',
        icon: "/images/icons/menu-products-icon.svg",
        subMenus: [
            { path: "/admin/categories", name: "Danh mục sản phẩm" }
        ]
    },

    {
        id: 3,
        name: 'Thương hiệu',
        path: '/admin/brands',
        icon: "/images/icons/menu-products-icon.svg",
        subMenus: [
            { path: "/admin/brands", name: "Danh sách thương hiệu" }
        ]
    },
    {
        id: 4,
        name: 'Cấu hình website',
        path: '/admin/configuration',
        icon: "/images/icons/menu-products-icon.svg",
        subMenus: [
            { path: "/admin/configuration", name: "Cấu hình website" }
        ]
    }
];
