import { Grid, Paper } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ImagesUpload from '../../../components/ImagesUpload/ImagesUpload';
import PageHeader from '../../../components/PageHeader/PageHeader';
import BrandService from '../../../services/brand/BrandService';
import { BrandDomain } from '../../../services/brand/types/BrandDomain';
import CategoryService from '../../../services/category/CategoryService';
import { CategoryDomain } from '../../../services/category/type/CategoryDomain';
import productService from '../../../services/product/productService';
import { ProductCreateRequest } from '../../../services/product/type/ProductCreateRequest';
import { VariantDomain } from '../../../services/variant/type/VariantDomain';
import { VariantUpsertModel } from '../../../services/variant/type/VariantUpsertModel';
import { VariantUpsertRequest } from '../../../services/variant/type/VariantUpsertRequest';
import variantService from '../../../services/variant/VariantService';
import { useSnackbar } from "notistack";
import "./productCreate.styles.scss";
import { optionSnackbarError, optionSnackbarSuccess } from '../../../utilities/Option';
import DeleteIcon from '@material-ui/icons/Delete';
import Spinner from '../../../components/Spin/Spinner';
import CKEditor from "react-ckeditor-component";

interface IProps extends RouteComponentProps {

}

const ProductCreate: React.FC<IProps> = (props: IProps) => {
    const initialProduct = {
        sex: 'MALE',
        imageUrls: [],
        videos: []
    }
    const [ product, setProduct ] = useState<any>(initialProduct);
    const [ variants, setVariants ] = useState<Array<any>>([]);
    const [ categories, setCategories ] = useState<Array<any>>([]);
    const [ categorySelected, setCategorySelected ] = useState<CategoryDomain>();
    const [ brands, setBrands ] = useState<Array<BrandDomain>>([]);
    const [ brandSelected, setBrandSelected ] = useState<BrandDomain>();
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);
    const [ description, setDescription ] = useState("");

    useEffect(() => {
        fetchCategories();
        fetchBrands();
    }, []);
 
    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await CategoryService.filterCategories();
            setCategories(response);
            setCategorySelected(response[0]);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const fetchBrands = async () => {
        try {
            setLoading(true);
            const response = await BrandService.filterBrands();
            setBrands(response);
            setBrandSelected(response[0]);
        } catch (error) {
        }finally {
            setLoading(false);
        }
    }

    const addVariant = () => {
        setVariants([ ...variants, new VariantDomain()])
    }

    const handleChangeProductCategory = (e: any) => {
        const newCategory = categories?.find((item: CategoryDomain) => item.id === Number(e?.target?.value));
        setCategorySelected(newCategory);
    }

    const handleChangeBrand = (e: any) => {
        const newBrand = brands?.find((item: BrandDomain) => item.id === Number(e?.target?.value));
        setBrandSelected(newBrand);
    }

    const createProduct = async () => {
        setLoading(true);
        try {
            const request = new ProductCreateRequest(product);
            request.brandId = brandSelected?.id;
            request.categoryId = categorySelected?.id;
            request.brandId = brandSelected?.id;
            request.categoryId = categorySelected?.id;
            request.imageUrls = product?.imageUrls?.join(",") || "";
            request.description = description;
            const response = await productService.createProduct(request);

            const variantUpsertRequest = new VariantUpsertRequest();
            variantUpsertRequest.productId = response.id;
            variantUpsertRequest.variants = variants.map((variant: any) => new VariantUpsertModel({ id: null, sku: variant?.sku, volume: variant?.volume, price: variant?.price, totalPrice: variant?.totalPrice }));
            await variantService.upsert(variantUpsertRequest);
            props.history.replace(`/admin/products/${response?.id}`);
            enqueueSnackbar("Tạo mới sản phẩm thành công!", optionSnackbarSuccess);
        } catch (error) {
            enqueueSnackbar("Có lỗi xảy ra", optionSnackbarError);
        } finally {
            setLoading(false);
        }
    }

    const handleChangeVariantSku = (e: any, index: number) => {
        const newVariants = [ ...variants ];
        newVariants[index].sku = e.target.value;
        setVariants(newVariants);
    }

    const handleChangeVariantVolume = (e: any, index: number ) => {
        const newVariants = [ ...variants ];
        newVariants[index].volume = Number(e.target.value) || '';
        setVariants(newVariants);
    }

    const handleChangeVariantPrice = (e: any, index: number) => {
        const newVariants = [ ...variants ];
        newVariants[index].price = Number(e.target.value) || '';
        setVariants(newVariants);
    }

    const handleChangeTotalPrice = (e: any, index: number) => {
        const newVariants = [ ...variants ];
        newVariants[index].totalPrice = Number(e.target.value) || '';
        setVariants(newVariants);
    }

    const addImage = (imageUrl: string) => {
        const newProduct = { ...product };
        newProduct.imageUrls = [ ...newProduct?.imageUrls, imageUrl ]
        setProduct(newProduct);
    }

    const removeImage = (index: number) => {
        const newProduct = { ...product };
        newProduct?.imageUrls?.splice(index, 1)
        setProduct(newProduct);
    }

    const removeVariant = (index: number) => {
        const newVariants = [...variants];
        newVariants.splice(index, 1);
        setVariants(newVariants);
    }

    const handleDescriptionChange = (evt: any) => {
        const newDescription = evt.editor.getData();
        setDescription(newDescription)
    }

    return (
        <div className="product-create">
            <PageHeader
                title="Thêm mới sản phẩm"
                actionGroup={<div><button className='btn sapo-btn-default' onClick={createProduct}>Thêm mới</button></div>}
            ></PageHeader>
            <Paper style={{ clear: "both", padding: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item container alignItems='center'>
                        <Grid className='product-detail__label' xs={4}>Tên sản phẩm</Grid>
                        <Grid xs={8}>
                            <input
                                value={product?.name}
                                className='sapo-textbox'
                                onChange={(e: any) => setProduct({ ...product, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Hình ảnh sản phẩm</Grid>
                        <Grid xs={8} className='product-detail__images'>
                            <ImagesUpload
                                images={product?.imageUrls}
                                addImageURL={addImage}
                                onDelete={removeImage}
                            />
                        </Grid>
                    </Grid>

                    {/* <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Video sản phẩm</Grid>
                        <Grid className='product-detail__images'>
                            <ImagesUpload
                                images={product?.videos}
                                addImageURL={addVideo}
                                onDelete={removeImage}
                            />
                        </Grid>
                    </Grid> */}

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Danh mục</Grid>
                        <Grid xs={8}>
                            <select value={categorySelected?.id} onChange={handleChangeProductCategory}>
                                {
                                    categories?.map((item: CategoryDomain) => (
                                        <option value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Thương hiệu</Grid>
                        <Grid xs={8}>
                            <select value={brandSelected?.id} onChange={handleChangeBrand}>
                                {
                                    brands?.map((item: BrandDomain) => (
                                        <option value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Giới tính</Grid>
                        <Grid xs={8}>
                            <select value={product?.sex} onChange={(e: any) => setProduct({ ...product, sex: e.target.value })}>
                                <option value="MALE">NAM</option>
                                <option value="FEMALE">NỮ</option>
                                <option value="OTHER">KHÁC</option>
                            </select>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Công thức</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.formula}
                                onChange={(e) => setProduct({ ...product, formula: e?.target?.value })}
                                placeholder="VD: Dạng xịt, ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Kích cỡ sản phẩm</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.size}
                                onChange={(e) => setProduct({ ...product, size: e?.target?.value })}
                                placeholder="VD: Size du lịch, ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Nhóm Hương</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.smell}
                                onChange={(e) => setProduct({ ...product, smell: e?.target?.value })}
                                placeholder="VD: Cỏ, Hoa, Tươi mát, Trái Cây, ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Nồng độ hương</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.smellConcentration}
                                onChange={(e) => setProduct({ ...product, smellConcentration: e?.target?.value })}
                                placeholder="VD: EDP, ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Mùi hương chính</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.mainSmell}
                                onChange={(e) => setProduct({ ...product, mainSmell: e?.target?.value })}
                                placeholder="VD: Hoa oải hương, hương vanilla, ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Độ lưu hương</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.scentRetentionPeriod}
                                onChange={(e) => setProduct({ ...product, scentRetentionPeriod: e?.target?.value })}
                                placeholder="VD: Lâu - 7h đến 12h ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Độ tỏa hương</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.fragrance }
                                onChange={(e) => setProduct({ ...product, fragrance: e?.target?.value })}
                                placeholder="VD: Xa - Trong vòng bán kinh 2m ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Phong cách</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.style}
                                onChange={(e) => setProduct({ ...product, style: e?.target?.value })}
                                placeholder="VD: Nam tính, cuốn hút, trẻ trung ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Xuất xứ</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.origin}
                                onChange={(e) => setProduct({ ...product, origin: e?.target?.value })}
                                placeholder="VD: Việt Nam, ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Năm phát hành</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.releaseYear}
                                onChange={(e) => setProduct({ ...product, releaseYear: e?.target?.value })}
                                placeholder="VD: 2020 ..."
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Thời gian bảo hành</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.guaranteePeriod}
                                type='number'
                                min={0}
                                onChange={(e: any) => setProduct({ ...product, guaranteePeriod: Number(e.target.value)})}
                                placeholder="VD: 12, 24 (đơn vị tháng)"
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Cân nặng</Grid>
                        <Grid xs={8}>
                            <input
                                className='sapo-textbox'
                                value={product?.weight}
                                type='number'
                                min={0}
                                onChange={(e: any) => setProduct({ ...product, weight: Number(e.target.value)})}
                                placeholder="VD: 300, 500, ... (đơn vị gam)"
                            />
                        </Grid>
                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid xs={4} className='product-detail__label'>Mô tả</Grid>
                        <Grid xs={8}>
                            <CKEditor
                                content={description}
                                events={{
                                    "change": handleDescriptionChange
                                }}
                            />
                            {/* <textarea rows={4} className='w-100' value={product?.description} onChange={(e: any) => setProduct({ ...product, description: e.target.value})} /> */}
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Paper>
            <Paper style={{ clear: "both", padding: '20px', marginTop: '20px', marginBottom: '50px' }}>
                <h3>Thông tin bán hàng</h3>
                {
                    variants?.map((variant: VariantDomain, index: number) => (
                        <>
                            <Grid container alignContent='center' className='mt-1' justify="space-between">
                                <Grid item xs={2} className="pr-2">
                                    <span>SKU</span>
                                    <input className='sapo-textbox' value={variant?.sku} onChange={(e: any) => handleChangeVariantSku(e, index)}/>
                                </Grid>
                                <Grid item xs={3} className="pr-2">
                                    <span>Thể tích</span>
                                    <input className='sapo-textbox' value={variant?.volume} onChange={(e: any) => handleChangeVariantVolume(e, index)}/>
                                </Grid>
                                <Grid item xs={3}  className="pr-2">
                                    <span>Giá(không bao gồm khuyến mại)</span>
                                    <input className='sapo-textbox' value={variant?.price} onChange={(e: any) => handleChangeVariantPrice(e, index)} />
                                </Grid>
                                <Grid item xs={3}  className="pr-2">
                                    <span>Giá bán</span>
                                    <input className='sapo-textbox' value={variant?.totalPrice} onChange={(e: any) => handleChangeTotalPrice(e, index)} />
                                </Grid>
                                <DeleteIcon
                                    style={{ cursor: 'pointer', color: 'red', marginTop: '24px'}}
                                    onClick={() => removeVariant(index)}
                                />
                            </Grid>
                        </>
                    ))
                }

                <div className='plus-icon' onClick={addVariant}>
                    <AddCircleOutline />
                </div>
            </Paper>
            {
                loading && (
                    <Spinner  />
                )
            }
        </div>
    )
}

export default ProductCreate;