export default {
    clearText: 'Xoá',
    closeText: 'Đóng',
    loadingText: 'Đang tải…',
    noOptionsText: 'Không có lựa chọn nào',
    openText: 'Mở',
    root:{
      fontSize: 13
    }
  };
  