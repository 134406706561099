export class VariantDomain {
    createdAt: string | null = null;
    createdBy: string | null = null;
    deleted: boolean  = false;
    id: number | null = null;
    price: number  = 0;
    sku: string = "";
    updatedAt: string | null = null;
    updatedBy: number | null = null;
    volume: number = 0;
    totalPrice: number = 0;
}