import apiClient from "../../utilities/ApiClient";
import { ConfigurationDomain } from "./type/ConfigurationDomain";

const ConfigurationService = {
    async save(request: ConfigurationDomain): Promise<any> {
        try {
            let response = await apiClient.post<any>("api/configuration", request)
            return response;
        } catch (error) {
            throw error;
        }
    },
    async get(): Promise<ConfigurationDomain> {
        try {
            let response = await apiClient.get<any>("api/configuration")
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default ConfigurationService;
