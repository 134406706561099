import apiClient from "../../utilities/ApiClient";
import { ProductCreateRequest } from "./type/ProductCreateRequest";
import { ProductFilterRequest } from "./type/ProductsFilterRequest";
import { ProductUpdateRequest } from "./type/ProductUpdateRequest";

const productService = {
    async filterProducts(request: ProductFilterRequest): Promise<any> {
        try {
            let response = await apiClient.get<any>("api/products", request)
            return response;
        } catch (error) {
            throw error;
        }
    },
    async createProduct(request: ProductCreateRequest): Promise<any> {
        try {
            let response = await apiClient.post<any>("api/products", request)
            return response;
        } catch (error) {
            throw error;
        }
    },
    async fetchProductDetail(id: any): Promise<any> {
        try {
            let response = await apiClient.get<any>(`api/products/${id}`)
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateProduct(id: any, request: ProductUpdateRequest): Promise<any> {
        try {
            let response = await apiClient.put<any>(`api/products/${id}`, request)
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default productService;
