import apiClient from "../../utilities/ApiClient";
import { BrandDomain } from "./types/BrandDomain";

const BrandService = {
    async filterBrands(): Promise<Array<BrandDomain>> {
        try {
            let response = await apiClient.get<Array<BrandDomain>>("api/brands");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async upsert(request: any): Promise<any> {
        try {
            let response = await apiClient.post<Array<BrandDomain>>("api/brands", request);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default BrandService;
